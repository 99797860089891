
import React, { Component } from "react";

import FavoriteIcon from '@mui/icons-material/Favorite';


export default class Blog extends Component {
  render() {
    return (

      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={this.props.img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
             <h5 className="text-center" style={{ fontFamily: 'Dancing Script',fontSize: window.innerWidth >= 550 ? '40px' : '30px'}}>
                {this.props.title1} <br/><img style={{width: window.innerWidth >= 550 ? '60px': '40px'}} src={this.props.and}/> <br/>{this.props.title3}
              </h5> 
               <h3 className="text-center">
                {this.props.title2}
              </h3>
               <h4 className="text-center">
                {this.props.message} <br/>
             <FavoriteIcon className="animate__animated animate__pulse animate__delay-4s animate__infinite p-1" sx={{ color: "red", fontSize: 40, }} />
              </h4>             
               <h5 className="text-center ">
                {this.props.message1}
              </h5>
              <h4 className="text-center" style={{color: 'black', textShadow: '.5px .5px 1px rgb(7, 7, 7)' }}>
                {this.props.message2} <br/> și <br/>{this.props.message4}
              </h4>
              <h5 className="text-center">
                {this.props.message3}
              </h5>
              <h4 className="text-center" style={{color: 'black', textShadow: '.5px .5px 1px rgb(7, 7, 7)'}}>
                {this.props.tati}
              </h4>
              <h4 className="text-center pb-5" style={{color: 'black', textShadow: '.5px .5px 1px rgb(7, 7, 7)'}}>
                {this.props.mami}
              </h4>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

