import restaurant from "./images/resta.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/card.jpeg";
import imgOmSuflet from "./images/5m.jpg";
import imgOmSufletmb from "./images/5.jpg";
import imgheader from "./images/bebelus.jpg";
import imgheadermb from "./images/picioruse.jpg";
import img2 from "./images/noi4.jpg";
import imgheadermiini from "./images/coronita.jpg";
import imgheadermiinimb from "./images/coronita.jpg";
import imgconfirmare from "./images/11.jpg";
import inel from "./images/inele.gif";


const data = {
    introData: [{
        familia: "familia Bîrsan",
        mire: "Carmen-Ioana",
        mireasa: " Remus-Alexandru",
        copil: 'David',
        data: "28 Octombrie 2023",
        data_confirmare: "10 septembrie 2023",
        savedata: "~ Salvează Data ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "carmencraioveanu1983@gmail.com", 
       tel: "+39 329 755 7903",
       phone: "tel:+393297557903",
       viber: "viber://chat?number=%2B393297557903",
       whatsapp: "https://wa.me/+393297557903",
       messenger: "https://www.messenger.com/t/carmen.craioveanu",
       tel1: "+393 380 179 4395",
       phone1: "tel:+3933801794395",
       viber1: "viber://chat?number=%2B3933801794395",
       whatsapp1: "https://wa.me/+3933801794395",
       messenger1: "https://www.messenger.com/t/birsan.alex.9",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Cununia Religioasă și Sfântul Botez",
            localul: "Biserica Ortodoxă Română",
            name: "Firenze",
            data: "28 octombrie 2023, sâmbătă,",
            ora: "ora - 14:00",
            ora1: "",
            adress: "Viale Giovanni Amendola, 40, 50121 Firenze FI, Italy",
            harta: "https://goo.gl/maps/fqhuJM3dfqCCzzfb6",
            iframe: "" 
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentelor",
            localul: "Restaurantul",
            name: "La Selva",
            data: "28 octombrie 2023, sâmbătă,",
            ora: "ora 19:00",
            adress: "Militare per Barberino, 221, 50041 Calenzano FI, Italy",
            harta: "https://goo.gl/maps/657dnMzRpcatoazP6",
            iframe: "" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "Te invit la Botezul meu!",
            title2: "Numele meu este David Bîrsan,",
            message: "Iar aceasta este prima mea petrecere",
            message1: "Nașii:",
            message2: "D'Aquino Andrea & Cristina",
            message4: 'Chirilă Bogdan',
            message3: "Moșii:",
            tati: "Gllas Shkelqim & Anghelina Ioana",
            mami: "",
            si: 'și',
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "Bîrsan Remus-Alexandru ",
            and: inel,
            title3: "Craioveanu Carmen-Ioana",
            title2: "Nunta Noastră și Cununia Religioasă!",
            message: "Pentru că cele mai frumoase momente se sărbătoresc alături de cei dragi, vrem să fim înconjurați de cei mai apropiați și scumpi oameni nouă. În acest context avem plăcerea de a vă invita să ne fiți martori și să împărtășiți bucuria celor mai semnificative evenimente din viața noastră.",
            message1: "Ne vor fi alături Nașii:",
            message2: " Nahoi Vasile & Simona ",
            tati: "Bîrsan Daniel & Marcela",
            si: 'și',
        }
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            message1: "CU BINECUVÂNTAREA PĂRINȚILOR",
            parintii_nume1: "Bîrsan Marius-Iulian și Liliana",
            parintii_nume2: "Craioveanu Iuliana",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Mai mult, mami și tati se căsătoresc!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Nu uitați să luați cu voi un car de voie bună și opinci de dansat!!!",
            message: "P.S: Vom fi recunoscători pentru confirmarea prezenței DVS!",
        }
    ],

}

export default data;